:root {
  --background: #000;
  --foreground: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--foreground);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  overflow-x: hidden;
  word-wrap: break-word;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--background);
  width: 100%;
}
.page {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
}

.chat-shadow {
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 15px,
    rgb(255 255 255 / 15%) 0px 0px 3px 1px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.role-selector {
  @apply px-6  text-center py-3 mb-6 w-80 bg-gray-100 focus:bg-black rounded-3xl border-none focus:ring-1 ring-brand-100 outline-none text-gray-200 appearance-none;
  text-align-last: center;
}
select {
  appearance: none;
  background: transparent;
}

.menu-items {
  display: none;
}
.menu-btn:hover .menu-items {
  position: absolute;
  z-index: 80;
  display: flex;
}
